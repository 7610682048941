<template>
  <div v-if="question">
    <div class="flex justify-between mb-1">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        question.name
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="question.required"
        >Required</span
      >
    </div>
    <div class="grid grid-cols-2 gap-2 max-w-md">
      <sqr-input-text
        name="gname"
        autocomplete="given-name"
        :placeholder="question.givenNamePlaceholder || 'Given Name'"
        :value="answer.givenName"
        @change="change('givenName', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        name="fname"
        autocomplete="family-name"
        :placeholder="question.familyNamePlaceholder || 'Family Name'"
        :value="answer.familyName"
        @change="change('familyName', $event)"
        :disabled="disabled"
        :v="v"
      />
    </div>
  </div>
</template>

<script>
import { assocPath } from 'ramda';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'NameView',
  components: { SqrInputText },
  props: {
    question: { type: Object },
    answer: { type: Object, default: () => ({}) },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  methods: {
    change(prop, value) {
      const step1 = assocPath([prop], value, this.answer ?? {});
      const label = [step1.givenName, step1.familyName]
        .filter(v => Boolean(v))
        .join(' ');
      const step2 = assocPath(['label'], label, step1);
      this.$emit('change', step2);
    },
  },
};
</script>
